<template>
  <div id="workbenchWarp" style="margin: -20px -20px 0 -20px;" ref="workbenchWarp">
    <div style="height: 100%" ref="workbenchWarpSecond">
      <WujieVue
        width="100%"
        height="100%"
        name="WujieVue"
        :url="url"
        :fetch="fetch"
        :props="wujieProps"
        :plugins="wujiePlugins"
      />
      <!---插屏广告--->
      <div class="mask" v-if="adDialogVisible && adList1">
        <div class="ad-video" v-if="adList1.adForm === 1">
          <img class="ad-top-image" :src="adList1.topImageUrl" alt="">
          <div class="ad-middle-image">
            <img class="" :src="adList1.middleImageUrl" alt="">
            <div class="ad-video-container">
              <ali-player
                :video-id="adList1.adVideoId"
              />
            </div>
          </div>
          <img class="ad-bottom-image" :src="adList1.bottomImageUrl" alt="">
          <span class="btn-close" @click="adDeviceLogCount(adList1.id)" v-if="adList1.isAllowClose !== 0">关闭</span>
        </div>
        <div class="mask" v-if="adList1.adForm === 0">
          <img
            v-if="adList1.adImageUrl"
            :src="adList1.adImageUrl"
            @click="goToAdvertPage(adList1, true)"
            class="mask-img"
            alt=""
          >
          <span class="btn-close cursor-pointer" @click="adDeviceLogCount(adList1.id)" v-if="adList1.isAllowClose !== 0">关闭</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WujieVue from 'wujie-vue2'
import { getToken } from '../../utils/auth'
import ResizeObserver from 'resize-observer-polyfill'
import { jwtToken } from '@jsh/helper/utils'
import { CloudUser } from '@/apis'
import { hasPermission } from '@/utils'
import AliPlayer from '@/views/home/ali-video-player/ali-video-player'
import { InstanceofPlugin, WindowSizePlugin } from 'wujie-polyfill'
export default {
  components: {
    WujieVue,
    AliPlayer
  },
  data () {
    return {
      wujieProps: {
        // 易理货令牌
        token: getToken(),
        // 业务端：客户
        itemCode: 'customer',
        // 唯一标识
        userId: null
      },
      url: `${process.env.VUE_APP_HWORK_HOST}/desktop-workbench/ylh-workbench/index`,
      fetch: window.fetch,
      adDialogVisible: false,
      adList1: [],
      wujiePlugins: [InstanceofPlugin(), WindowSizePlugin()]
    }
  },
  methods: {
    // 退出
    logout () {
      window.location.href = `${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`
    },
    // 获取插屏广告
    getAds () {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        this.userId = info.id
      }
      const deviceCode =
        !this.memberId || !this.userId ? null : this.memberId + '_' + this.userId
      console.log(deviceCode)
      this.$http.post(CloudUser.getAdvertInfoList, {
        positionCode: 'ylh-pc-main',
        adTypeCode: 'insert',
        deviceCode: deviceCode
      }).then(res => {
        if (res?.data?.success && res?.data?.data && res.data.data.length) {
          this.adList1 = res.data.data[0]
          // this.adList1 = {
          //   adForm: 0,
          //   adImageUrl: 'https://image.ylhtest.com/2024/09/02/8cd785d0ed244404a6913f43c44f143e.jpg'
          // }
          this.adDialogVisible = true

          if (this.adList1?.topImageUrl && this.adList1.topImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.topImageUrl = ''
          }
          if (this.adList1?.middleImageUrl && this.adList1.middleImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.middleImageUrl = ''
          }
          if (this.adList1?.bottomImageUrl && this.adList1.bottomImageUrl.indexOf('ecm/images/no-img.png') !== -1) {
            this.adList1.bottomImageUrl = ''
          }
        } else {
          this.adDialogVisible = false
        }
      })
    },
    // 广告点击统计
    advertCount (ids, adPositionCode, adTypeCode) {
      this.$http.post(CloudUser.insertYlhAdcountLog, {
        adPositionCode: adPositionCode,
        adTypeCode: adTypeCode,
        adId: ids
      }).then(res => {
        if (!res.data.success) {
          console.log(res.data.errorMessage)
        }
      })
    },
    // 广告上报
    adDeviceLogCount (ids) {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      this.$http.post(CloudUser.setAdDeviceLog, {
        adId: ids,
        adTypeCode: 'insert',
        deviceCode: info.member.id + '_' + info.id
      }).then(res => {
        if (!res.data.success) {
          console.log(res.data.errorMessage)
        }
      })
      this.adDialogVisible = false
    },
    // 广告界面跳转
    goToAdvertPage (obj, isFalg) {
      if (isFalg) {
        this.advertCount(obj.id, 'ylh-pc-main', 'insert')
        this.adDeviceLogCount(obj.id)
      } else {
        this.advertCount(obj.id, 'ylh-pc-right-login', 'banner-pc')
      }
      if (obj.source) {
        this.$http.get(CloudUser.getCompleteJumpUrl, {
          source: obj.source,
          linkUrl: obj.pcUrl
        })
          .then(res => {
            if (res.data.success) {
              window.open(decodeURIComponent(res.data.data))
            }
          })
          .catch(() => {
            this.$message.error('服务器异常')
          })
      } else {
        // 链接
        if (obj.linkType === 1) {
          const webUrl = location.origin + '/webv2/advert-detail/' + obj.id
          self.location.href = webUrl
          console.log('111111', webUrl)
          // window.open(webUrl)
        } else if (obj.linkType === 2) {
          // 链接
          if (obj.linkUrlType === 'INNER') {
            // 内部链接
            if (obj.authorizationCode) {
              this.adPermission = hasPermission(obj.authorizationCode)
              if (this.adPermission) {
                // self.location.href = location.origin + obj.h5Url
                console.log('22222', location.origin + obj.h5Url)
                window.open(location.origin + obj.h5Url)
              } else {
                this.$message.error('无权限查看')
              }
            } else {
              // self.location.href = location.origin + obj.h5Url.trim()
              window.open(location.origin + obj.h5Url.trim())
              console.log('3333', location.origin + obj.h5Url)
            }
          } else {
            // 外链
            const url = obj.pcUrl
            const hostUrl = url.split('/')
            if (
              hostUrl[2] === 'help.yilihuo.com' ||
              hostUrl[2] === 'h.yilihuo.com'
            ) {
              obj.pcUrl =
                obj.pcUrl +
                '?JSESSIONID=' +
                localStorage.getItem('sessionId') +
                '&type=1'
            }
            if (obj.h5UrlType === 3) {
              const tokenData = JSON.parse(localStorage.getItem('tokenData'))
              // 8码不存在直接返回
              if (!tokenData?.seller_code) { return }
              obj.pcUrl = obj.pcUrl.includes('?')
                ? `${obj.pcUrl}&userId=${tokenData?.seller_code}&personnelUserId=${this.userId}`
                : `${obj.pcUrl}?userId=${tokenData?.seller_code}&personnelUserId=${this.userId}`
            }
            // self.location.href = obj.pcUrl.trim()
            console.log(location.origin + obj.h5Url)
            window.open(obj.pcUrl.trim())
          }
        }
      }
    }
  },
  created () {
    const data = jwtToken.decode(getToken())
    this.wujieProps.userId = data.user_id
    if (!jwtToken.checkTokenValid(getToken())) {
      // 令牌失效，重新登录
      this.logout()
    }
    this.getAds()
  },
  mounted () {
    this.ro = new ResizeObserver((entries) => {
      entries.forEach(() => {
        // 页面高度减去头部高度减去底部高度
        const minHeight = window.innerHeight - 64 - 30
        const height = this.$refs.workbenchWarpSecond.clientHeight
        if (minHeight >= height) {
          this.$refs.workbenchWarp.style.height = minHeight + 'px'
        } else {
          this.$refs.workbenchWarp.style.height = ''
        }
      })
    })
    this.ro.observe(this.$refs.workbenchWarp, {
      box: 'border-box'
    })
  }
}
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  .btn-close {
    position: absolute;
    z-index: 9999;
    font-family: FZLanTingHei-R-GBK;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #606266;
    right: 66px;
    top: 44px;
    width: 71px;
    height: 28px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 14px;
    border: 1px solid #909399;
  }
  .mask-img {
    position: relative;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
  }
}
.ad-video {
  width: 100vw;
  height: 100%;
  .ad-top-image {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: auto;
    z-index: 101;
  }
  .ad-middle-image {
    position: relative;
    left: 0px;
    width: 100vw;
    height: 100%;
    z-index: 99;
    vertical-align: center;
    img {
      width: 100vw;
      height: auto;
    }
  }
  .ad-bottom-image {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: auto;
    z-index: 102;
    pointer-events: none;
  }
  .ad-video-container {
    position: absolute;
    left: calc((100vw - 285.0 / 1440.0 * 100vw) / 2.0);
    top: calc(152.5 * 100vw / 1440.0);
    width: calc(290.0 / 1440.0 * 100vw);
    height: calc((290.0 / 1440.0 * 100vw) * 504.0 / 290.0);
    z-index: 100;
  }
}
</style>
