<template>
  <div>
    <div v-loading="loading" class="d-flex justify-content-center video-container">
      <div id="Jsh_videoPlayer"/>
    </div>
  </div>
</template>

<script>
import { AliVideoPlayer } from '@jsh/helper/utils'
import { CloudUser } from '../../../apis'

export default {
  data () {
    return {
      loading: false
    }
  },
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  methods: {
    videoPlay () {
      const owner = this
      if (!owner.videoId) {
        owner.$emit('close')
        return
      }
      owner.loading = true
      owner.$http.get(
        CloudUser.getAliVideoUrl,
        {
          videoId: owner.videoId
        },
        {
          withCredentials: true,
          headers: {
            Authorization: null
          }
        }
      )
        .then((res) => {
          if (res.data.success) {
            // eslint-disable-next-line no-new
            new AliVideoPlayer({
              id: 'Jsh_videoPlayer',
              width: '100%',
              height: '100%',
              autoplay: true,
              source: res.data.data.payInfoList[0].playUrl,
              cover: res.data.data.coverUrl
            })
          } else {
            owner.$emit('close')
            owner.$message.error(res.data.errorMsg)
          }
        })
        .finally(() => {
          owner.loading = false
        })
    }
  },

  created () {
    const owner = this
    owner.videoPlay()
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  width: calc(290.0 / 1440.0 * 100vw);
  height: calc((290.0 / 1440.0 * 100vw) * 504.0 / 290.0);
}
</style>
